import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';


import {
	doReq
}
from 'voUtils/RestEx.js';

import {
	findResultFields
}
from 'voUtils/BrowserEx.js';

export default {
	data: function () {
		return {
			units: [],
			//regionPath: String
			regionid: undefined
		};
	},
	props: {
		region: Number,
		// 	sorting: String,
		// 	max: String
	},
	beforeMount: function () {
		var results = this.$el.getElementsByClassName('unit-list-section');
		if (results.length) {
			this.resultFields = findResultFields(results[0].innerHTML, this.$el);
		}
		console.log('regionId is::' + this.getRegionId());
		var req = {
			fields: this.resultFields,
			filter: {
				//unitgroupid: 13335,
				region: this.getRegionId()
			},
			sorting: 'random',
			max: 3
		};

		doReq('searchUnits', req)
			.then((res) => {
				this.units = res.units;
			});

	},

	updated: function () {
		$('[data-toggle="tooltip"]').tooltip();
		$('[data-toggle="popover"]').popover();
	},
	methods: {
		getRegionId: function () {
			if (!this.regionPath) {
				let el = document.querySelector("[data-regionid]");
				if (el) {
					console.log('el.getAttribute(data-regionid):' + this.$el.dataset.regionid);
					this.regionid = this.$el.dataset.regionid;
				}
			}

			return this.regionid;
		},
		getUnitSearchTarget: function (id) {
			if (window.screen.width < 768) {
				//probably phone?
				return '_self';
			} else {
				return 'unit_' + id;
			}
		},
	}

};